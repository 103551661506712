import { useState, useEffect } from 'react';

import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import AboutUs from './Pages/AboutUs/AboutUs';
import Contact from './Pages/Contact/Contact';
import Footer from './Pages/Footer/Footer';

import en from './assets/language/en.json';
import pt from './assets/language/pt.json';
import es from './assets/language/es.json';
import Loading from './Pages/Loading/Loading';

function App() {

    const [language, setLanguage] = useState('');
    const [translation, setTranslation] = useState({});
    const [theme, setTheme] = useState('');

    const changeLanguage = (value) => {
        setLanguage(value);
        setTranslation(switchLanguage(value));
    }

    const switchLanguage = (value) => {
        localStorage.setItem("language", value);
        switch (value) {
            case 'en':
            default:
                return en;
            case 'pt':
                return pt;
            case 'es':
                return es;
        }
    }

    useEffect(() => {
        var usrlang = navigator.language
            || navigator.userLanguage;
        var language = usrlang.split("-")[0];
        const defaultLanguage = localStorage.getItem("language") ?? language;
        const defaultTheme = localStorage.getItem("theme") ?? 'light';
        changeLanguage(defaultLanguage);
        changeTheme(defaultTheme);
    }, []);

    const goTo = (element) => {
        const item = document.getElementById(element);
        const position = item.offsetTop;
        window.scrollTo(0, position - 100);
    }

    const validateThemeFromStorage = () => {
        const themeFromStorage = localStorage.getItem("theme");
        if (themeFromStorage !== null) {
            setTheme(themeFromStorage);
        }
        document.body.removeAttribute('class');
        document.body.setAttribute('class', themeFromStorage);
        return theme;
    }

    const changeTheme = (theme) => {
        localStorage.setItem("theme", theme);
        validateThemeFromStorage();
    }

    return language && theme ? (<>
        <Home language={language} translation={translation} changeLanguage={changeLanguage} theme={theme} changeTheme={changeTheme} goTo={goTo} />
        <AboutUs language={language} translation={translation} />
        <Services language={language} translation={translation} goTo={goTo} />
        <Contact language={language} translation={translation} />
        <Footer language={language} translation={translation} goTo={goTo} />
    </>) : (<Loading />);
}

export default App;