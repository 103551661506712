import { Component, useEffect, useState } from 'react';

import './Home.scss';
import './Responsive.scss';
import Loading from '../Loading/Loading';

const Home = (props) => {

    const [languageOpen, setLanguageOpen] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [activeMenu, setActiveMenu] = useState('home');
    const [mobileMenu, setMobileMenu] = useState(false);

    const changeLanguage = (value) => {
        props.changeLanguage(value);
    }

    setTimeout(function () {
        setShowLoading(false);
    }, 2000);

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.menu');
        const scrollTop = window.scrollY;
        scrollTop >= 59 ? header.classList.add('fixed') : header.classList.remove('fixed');
        validateMenuOnScroll(scrollTop);
    };

    const validateMenuOnScroll = (scroll) => {
        const elements = props.translation.menu;
        for (var i = 0; i < elements.length; i++) {
            const item = elements[i];
            const element = document.getElementById(item.value);
            const height = element?.offsetHeight;
            const top = element?.offsetTop;
            if (scroll >= (parseInt(top) - 200) && scroll <= (parseInt(height) + parseInt(top))) {
                setActiveMenu(item.value);
            }
        }
    }

    return (<>
        {showLoading ? (<Loading />) : null}
        <div className="slider" id="home">
            <div className="slide">
                <div className="caption">
                    <div>{props.translation.slider.first_caption}</div>
                    <div>{props.translation.slider.second_caption}</div>
                    <div className="button" onClick={() => { props.goTo('contact') }}>{props.translation.slider.button}</div>
                </div>
            </div>
        </div>

        <header className="header">
            <div className="container">
                <div className="contact">
                    <a href="mailto:contact@aeait.com" className="email">contact@aeait.com</a>
                    <div className="phone">
                        <a href="https://api.whatsapp.com/send?phone=393517645709" target="_blank">+39 351 764 5709</a>
                        <a href="https://api.whatsapp.com/send?phone=393515869527" target="_blank">+39 351 586 9527</a>
                    </div>
                </div>
                <div className="options">
                    <a href="https://www.linkedin.com/company/aeait" target="_blank">Linkedin A&A IT</a>
                    <div className="change-mode" onClick={() => { props.changeTheme(props.theme === 'light' ? 'dark' : 'light') }}></div>
                    <ul className={languageOpen ? 'language open' : 'language'} onClick={() => { setLanguageOpen(!languageOpen) }}>
                        <li className={props.language === 'en' ? 'english active' : 'english'} onClick={() => { changeLanguage('en') }}>EN</li>
                        <li className={props.language === 'pt' ? 'portuguese active' : 'portuguese'} onClick={() => { changeLanguage('pt') }}>PT</li>
                        <li className={props.language === 'es' ? 'spanish active' : 'spanish'} onClick={() => { changeLanguage('es') }}>ES</li>
                    </ul>
                </div>
            </div>
        </header>

        <div className="menu" id="header">
            <div className="container">
                <a href="/">A&A IT</a>
                <ul>
                    {props.translation.menu.map((item, index) => {
                        return (
                            <li key={"header-menu-" + index} className={item.value === activeMenu ? 'active' : ''} onClick={() => { props.goTo(item.value) }}>{item.label}</li>
                        );
                    })}
                </ul>
                <div className="icon-menu" onClick={() => { setMobileMenu(true) }}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div className={mobileMenu ? 'menu-mobile active' : 'menu-mobile'}>
            <span onClick={() => { setMobileMenu(false) }}>X</span>
            <ul>
                {props.translation.menu.map((item, index) => {
                    return (
                        <li key={"mobile-menu-" + index} onClick={() => { props.goTo(item.value); setMobileMenu(false); }}>{item.label}</li>
                    );
                })}
            </ul>
        </div>
    </>);
}

export default Home;