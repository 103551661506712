import './AboutUs.scss';
import './Responsive.scss';

const AboutUs = (props) => {
    return (<>
        <div className="about-us" id="about">
            <div className="container">
                <div className="text">
                    <div className="title">
                        <div className="small">{props.translation.about.small}</div>
                        <div className="large">{props.translation.about.large}</div>
                    </div>
                    <h2>{props.translation.about.text}</h2>
                </div>
            </div>
        </div>
    </>);
}

export default AboutUs;