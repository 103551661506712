import './Services.scss';
import './Responsive.scss';

const Services = (props) => {
    return (<>
        <div className="services" id="services">
            <div className="container">
                <div className="title">
                    <div className="small">{props.translation.services.small}</div>
                    <div className="large">{props.translation.services.large}</div>
                </div>
                <ul>
                    {props.translation.services.list.map((item, index) => {
                        return (
                            <li key={"service-" + index}>
                                <div className="icon"></div>
                                <div className="title">{item.title}</div>
                                <div className="description">{item.description}</div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
        <div className="message">{props.translation.services.after} <span onClick={() => { props.goTo('contact') }}>{props.translation.services.afterspan}</span></div>
    </>)
}

export default Services;