import './Footer.scss';
import './Responsive.scss';

const Footer = (props) => {
    return (<>
        <footer>
            <div className="container">
                <div className="logo"></div>
                <ul>
                    {props.translation.menu.map((item, index) => {
                        return (
                            <li key={"footer-menu-"+index} onClick={() => { props.goTo(item.value) }}>{item.label}</li>
                        );
                    })}
                </ul>
                <h2>{props.translation.footer}</h2>
                <a href="https://www.linkedin.com/company/aeait" target="_blank">Linkedin A&A IT</a>
            </div>
        </footer>
    </>);
}

export default Footer;