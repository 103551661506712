import { useEffect, useState } from 'react';

import './Contact.scss';
import './Responsive.scss';

const SendMailUrl = 'https://sendmail.aeait.com'

const Contact = (props) => {

    const [interests, setInterests] = useState(props.translation.contact.form.interest.options);
    const [sending, setSending] = useState(false);
    const [fullname, setFullname] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const selectInterest = (index) => {
        var tmpOptions = interests.map((item, i) => {
            if (index === i) {
                item.selected = !item.selected;
            }
            return item;
        })
        setInterests(tmpOptions);
    }

    useEffect(() => {
        setInterests(props.translation.contact.form.interest.options);
    }, [props.translation]);

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        var interestsSelected = [];
        interests.map((item, i) => {
            if (item.selected) {
                interestsSelected.push(item.value);
            }
        });
        if (interestsSelected.length < 1) {
            alert(props.translation.contact.form.interest.error);
            return;
        }
        console.log(fullname);
        setSending(true);
        await fetch(SendMailUrl + '?type=contact', {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                subject: 'New website contact (A&A IT)',
                data: {
                    interests: interestsSelected.join(","),
                    name: fullname,
                    cellphone: cellphone,
                    subject: subject,
                    message: message
                }
            })
        })
        .then(res => res.json())
        .then(res => {
            setSending(false);
            if (res.error) {
                alert(props.translation.contact.form.error_message);
            } else {
                alert(props.translation.contact.form.success_message);
                var tmpOptions = interests.map((item, i) => {
                    item.selected = false;
                    return item;
                })
                setInterests(tmpOptions);
                setFullname('');
                setCellphone('');
                setSubject('');
                setMessage('');
            }
        })
        .catch(err => {
            setSending(false);
            alert(props.translation.contact.form.error_message);
        })
    }

    return (<>
        <div className="contactPage" id="contact">
            <div className="container">
                <div className="title">
                    <div className="small">{props.translation.contact.small}</div>
                    <div className="large">{props.translation.contact.large}</div>
                </div>
                <form name="contact" method="post" onSubmit={onSubmitHandler}>
                    <ul className="form">
                        <li className="field">
                            <label>{props.translation.contact.form.interest.label} *</label>
                            <ul>
                                {interests.map((item, index) => {
                                    return (<li key={"interest-"+index} className={item.selected ? 'active' : ''} onClick={() => { selectInterest(index) }}>{item.value}</li>);
                                })}
                            </ul>
                        </li>
                        <li className="float-label field">
                            <input type="text" name="full-name" placeholder=" " required value={fullname} onChange={(e) => { setFullname(e.target.value) }} />
                            <label>{props.translation.contact.form.fullname} *</label>
                        </li>
                        <li className="float-label field">
                            <input type="tel" name="cellphone" placeholder=" " value={cellphone} onChange={(e) => { setCellphone(e.target.value) }} />
                            <label>{props.translation.contact.form.cellphone} *</label>
                        </li>
                        <li className="float-label field nm">
                            <input type="text" name="subject" placeholder=" " value={subject} onChange={(e) => { setSubject(e.target.value) }} />
                            <label>{props.translation.contact.form.subject} *</label>
                        </li>
                        <li className="field">
                            <label>{props.translation.contact.form.message}</label>
                            <textarea placeholder=" " rows="3" defaultValue={message} onChange={(e) => { setMessage(e.target.value) }}></textarea>
                        </li>
                    </ul>
                    <input type="submit" className="button" disabled={sending} value={sending ? props.translation.contact.form.button_sending : props.translation.contact.form.button} />
                </form>
                <div className="infos">
                    <a href="mailto:contact@aeait.com">contact@aeait.com</a>
                    <a href="https://api.whatsapp.com/send?phone=393517645709" target="_blank">+39 351 764 5709</a>
                    <a href="https://api.whatsapp.com/send?phone=393515869527" target="_blank">+39 351 586 9527</a>
                </div>
            </div>
        </div>
    </>);
}

export default Contact;